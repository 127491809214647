exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-case-study-ampliflow-jsx": () => import("./../../../src/pages/case-study/ampliflow.jsx" /* webpackChunkName: "component---src-pages-case-study-ampliflow-jsx" */),
  "component---src-pages-case-study-managemypain-jsx": () => import("./../../../src/pages/case-study/managemypain.jsx" /* webpackChunkName: "component---src-pages-case-study-managemypain-jsx" */),
  "component---src-pages-case-study-vara-koto-jsx": () => import("./../../../src/pages/case-study/vara-koto.jsx" /* webpackChunkName: "component---src-pages-case-study-vara-koto-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-downloads-jsx": () => import("./../../../src/pages/downloads.jsx" /* webpackChunkName: "component---src-pages-downloads-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-schedule-a-meeting-jsx": () => import("./../../../src/pages/schedule-a-meeting.jsx" /* webpackChunkName: "component---src-pages-schedule-a-meeting-jsx" */),
  "component---src-pages-services-content-writing-jsx": () => import("./../../../src/pages/services/content-writing.jsx" /* webpackChunkName: "component---src-pages-services-content-writing-jsx" */),
  "component---src-pages-services-ui-design-jsx": () => import("./../../../src/pages/services/ui-design.jsx" /* webpackChunkName: "component---src-pages-services-ui-design-jsx" */),
  "component---src-pages-services-ux-design-jsx": () => import("./../../../src/pages/services/ux-design.jsx" /* webpackChunkName: "component---src-pages-services-ux-design-jsx" */),
  "component---src-pages-side-projects-jsx": () => import("./../../../src/pages/side-projects.jsx" /* webpackChunkName: "component---src-pages-side-projects-jsx" */),
  "component---src-pages-where-ive-traveled-jsx": () => import("./../../../src/pages/where-ive-traveled.jsx" /* webpackChunkName: "component---src-pages-where-ive-traveled-jsx" */),
  "component---src-templates-freebie-pages-jsx": () => import("./../../../src/templates/FreebiePages.jsx" /* webpackChunkName: "component---src-templates-freebie-pages-jsx" */)
}

